import React, { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import init, { marchingSquaresRandom } from "marching-squares";
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
	measurementId: process.env.REACT_APP_measurementId
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const App = () => {
	const [wasmInitialized, setWasmInitialized] = useState(false);

	useEffect(() => {
		init().then((_: any) => setWasmInitialized(true));
	}, []);

	return (
		<div className="App">
			{wasmInitialized ? <WasmLoaded /> : <p>Loading webassembly ...</p>}
		</div>
	);
};

const WasmLoaded = () => {
	const defaultThreshold = 0.1, defaultWidth = 10, defaultHeight = 10;

	const [images, setImages] = useState<undefined | HTMLImageElement[]>(undefined);
	const [imageIn, setImageIn] = useState<Float32Array>(Float32Array.of());
	const [imageOut, setImageOut] = useState<Int32Array>(Int32Array.of());
	const [threshold, setThreshold] = useState(0);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [selectedIndex, setSelectedIndex] = useState<undefined | { x: number, y: number }>(undefined);
	const visualWidth = 500,
		visualHeight = visualWidth * height / width;

	const canvasRef = useRef<HTMLCanvasElement>(null);

	const cellToCanvasSpace = (x: number, y: number): { x: number, y: number, width: number, height: number } => {
		const scaledX = Math.round(x / width * visualWidth),
			scaledY = Math.round(y / height * visualHeight);
		const scaledWidth = Math.ceil(visualWidth / width),
			scaledHeight = Math.ceil(visualHeight / height);
		return { x: scaledX, y: scaledY, width: scaledWidth, height: scaledHeight };
	};

	const render = (imgOut: Int32Array) => {
		if (canvasRef.current === null || images === undefined) return;
		const canvas = canvasRef.current,
			ctx = canvas.getContext('2d')!!;
		canvas.width = Math.ceil(visualWidth);
		canvas.height = Math.ceil(visualHeight);

		for (let y = 0; y < height; ++y)
			for (let x = 0; x < width; ++x) {
				const val = imgOut[y * width + x];
				const scaled = cellToCanvasSpace(x, y);
				ctx.drawImage(images[val], scaled.x, scaled.y, scaled.width, scaled.height);
			}
	};

	useEffect(() => {
		setWidth(defaultWidth);
		setHeight(defaultHeight);
		setThreshold(defaultThreshold);
	}, []);

	useMemo(() => {
		const longEvent = async () => {
			const images: Promise<HTMLImageElement>[] = new Array(16);
			for (let i = 0; i < 16; ++i)
				images[i] = loadImage("/img/" + i + ".jpg");
			setImages(await Promise.all(images));
		}
		longEvent();
	}, []);

	useMemo(() => {
		const longEvent = async () => {
			if (imageIn.length != (width + 1) * (height + 1)) setImageOut(Int32Array.of());
			const out: Int32Array = marchingSquaresRandom(width + 1, height + 1, threshold);
			setImageOut(out);
		};
		longEvent();
	}, [imageIn, width, height, threshold, canvasRef]);

	useEffect(() => {
		if (imageIn.length != (width + 1) * (height + 1)) return;
		render(imageOut);
		setSelectedIndex(undefined);
	}, [imageOut]);

	const randomizeMap = () => {
		const newMap = new Float32Array((width + 1) * (height + 1));

		for (let y = 0; y < height; ++y)
			for (let x = 0; x < width; ++x)
				newMap[y * width + x] = Math.random() * 10;

		setImageIn(newMap);
	};

	const canvasClicked: React.MouseEventHandler<HTMLCanvasElement> = (e) => {
		const longEvent = async () => {
			if (canvasRef.current === undefined) return;
			const canvas = canvasRef.current!!,
				context = canvas.getContext('2d')!!;
			const rect = canvas.getBoundingClientRect();
			const xIndex = Math.floor((e.clientX - rect.left) / rect.width * width);
			const yIndex = Math.floor((e.clientY - rect.top) / rect.height * height);

			render(imageOut);
			setSelectedIndex({ x: xIndex, y: yIndex });
			const scaled = cellToCanvasSpace(xIndex, yIndex);
			context.strokeStyle = "red";
			context.strokeRect(scaled.x, scaled.y, scaled.width, scaled.height);
		};
		longEvent();
	};

	return (<>
		<Box m="auto">
			<Grid container spacing={1} m='auto' p={3}
				alignItems={'center'}
				textAlign={'center'}
				justifyContent={'center'}
				alignContent={'center'}>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<Button onClick={randomizeMap}>
						Random Map
					</Button>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<TextField
						label="Width"
						type='number'
						defaultValue={defaultWidth}
						onChange={(e) => e.target.value == "" ? 0
							: setWidth(parseInt(e.target.value) ?? 0)} />
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<TextField
						label="Height"
						type='number'
						defaultValue={defaultHeight}
						onChange={(e) => e.target.value == "" ? 0
							: setHeight(parseInt(e.target.value))} />
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<TextField
						label="Threshold"
						type='number'
						defaultValue={defaultThreshold}
						onChange={(e) => e.target.value == "" ? 0
							: setThreshold(parseFloat(e.target.value))} />
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<p>{selectedIndex === undefined
						? "No cell selected"
						: ("Selected: " + imageOut[selectedIndex.y * width + selectedIndex.x])}</p>
				</Grid>
				<Grid item xs={12}>
					<canvas ref={canvasRef} onClick={canvasClicked} />
				</Grid>
			</Grid>
		</Box>
	</>);
};

const loadImage = (path: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const img = new Image();
		img.crossOrigin = 'Anonymous';
		img.src = path;
		img.onload = () => resolve(img);
		img.onerror = e => reject(e);
	});